import moment from 'moment';
import { LoadProductData } from '../../../types/Sagas';
import {
  DISCOUNT_TIERS,
  MILLISECONDS_MULTIPLIER,
  PRODUCT_TYPES,
} from '../../constants';
import Materials from './materials.json';
import { SegmentFavorite } from '../interfaces';

export const materialMapper = (
  categories: {
    id: number;
    name: string;
    parent_id: number;
    slug: string;
  }[],
): string => {
  if (!categories || !categories.length) {
    return '';
  }
  const materials = categories.reduce((acc, cat) => {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    if (cat?.id && Materials[cat.id as 205]) {
      acc.add(cat?.name || `${cat.id}`);
    }
    return acc;
  }, new Set<string>());

  return Array.from(materials).join(', ');
};

export const brandsReducer = (
  brands: { name: string }[],
): string[] | [] => {
  return brands?.length
    ? Array.from(
        brands.reduce((acc, brand) => {
          if (brand?.name) {
            acc.add(brand.name);
          }
          return acc;
        }, new Set<string>()),
      )
    : [];
};

export const brandMapper = (
  brand: string | { name: string }[],
): string | undefined => {
  let formattedBrand;

  if (typeof brand === 'string') {
    formattedBrand = brand;
  }

  if (Array.isArray(brand) && brand.length > 0) {
    formattedBrand = brand[0].name;
  }

  return formattedBrand;
};

const allowedCondition = new Set([
  'Giftable',
  'New',
  'Excellent',
  'Very Good',
  'Good',
  'Fair',
  'Flawed',
]);

declare type ConditionMap = {
  giftable: string;
  new: string;
  excellent_high: string;
  excellent: string;
  excellent_low: string;
  very_good_high: string;
  very_good: string;
  very_good_low: string;
  good_high: string;
  good: string;
  good_low: string;
  fair: string;
  flawed: string;
  worn: string;
};

const conditionMap: ConditionMap = {
  giftable: 'Giftable',
  new: 'New',
  excellent_high: 'Excellent',
  excellent: 'Excellent',
  excellent_low: 'Excellent',
  very_good_high: 'Very Good',
  very_good: 'Very Good',
  very_good_low: 'Very Good',
  good_high: 'Good',
  good: 'Good',
  good_low: 'Good',
  fair: 'Fair',
  flawed: 'Flawed',
  worn: 'Good',
};

const allowedCategory = new Set([
  'Accessories',
  'Bags',
  'Gift Card',
  'Jewelry',
  'Styles',
  'Watches',
]);

export const getCategory = (
  categories:
    | {
        id: number;
        name: string;
        parent_id: number;
        slug: string;
      }[]
    | string[],
  isGiftCard: boolean,
): string | null => {
  if (isGiftCard) {
    return 'Gift Card';
  }
  let parentCategory = '';
  (categories || []).forEach((category) => {
    const categoryName =
      typeof category === 'string' ? category : category?.name;
    if (allowedCategory.has(categoryName)) {
      parentCategory = categoryName;
    }
  });
  return parentCategory === 'Styles' ? 'Bags' : parentCategory;
};

export const mapCondition = (
  condition: string,
  isGiftable?: boolean,
): string => {
  let mappedCondition = condition.toLowerCase();
  if (isGiftable) {
    mappedCondition = 'giftable';
  } else if (mappedCondition === 'shows wear') {
    mappedCondition = 'very_good';
  }
  const displayCondition =
    conditionMap[mappedCondition as keyof ConditionMap] || condition;
  return allowedCondition.has(displayCondition)
    ? displayCondition
    : condition;
};

export const getCondition = (
  product: LoadProductData,
  isGiftCard: boolean,
): string | null => {
  const {
    condition,
    // giftable, // TO DO: this fix should be deployed when checkout has giftable as well
  } = product || {};
  if (isGiftCard || !condition) {
    return null;
  }
  return mapCondition(
    condition,
    // giftable, // TO DO: this fix should be deployed when checkout has giftable as well
  );
};

const MAX_DISCOUNT_PERCENTAGE = 30;
export const getLastCall = (product: SegmentFavorite): number => {
  const isLastCall =
    product?.lastCall ||
    product?.isLastCall ||
    (product?.dos || 0) > MAX_DISCOUNT_PERCENTAGE;
  return Number(isLastCall);
};

export const getDiscountTierRate = (
  product: SegmentFavorite,
): number => {
  const discountedTier =
    product.discounted_tier || product.discountedTier;
  const LAST_DISCOUNT_TIER = 5;
  // if discounted tier is 5, it's a last call item, so return the actual discounted value.
  if (getLastCall(product) || discountedTier === LAST_DISCOUNT_TIER) {
    return product?.dos || 0;
  }

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  return DISCOUNT_TIERS[discountedTier as 0]?.rate;
};

/** n.b. inconsistent data for product.color */
export const getColor = (
  product: LoadProductData,
  isGiftCard: boolean,
): string | null => {
  if (isGiftCard) {
    return null;
  }
  if (product?.color) {
    const { color } = product;
    // ensure color is Sentence case
    return color[0].toUpperCase() + color.slice(1);
  }
  if (product?.categories) {
    const colorCategories: string[] = [];
    const COLORES_PARENT_ID = 191;
    product?.categories.forEach((category) => {
      if (
        category?.parent_id === COLORES_PARENT_ID &&
        category?.name
      ) {
        colorCategories.push(category.name);
      }
    });
    if (colorCategories.length) {
      return colorCategories[0];
    }
  }
  return null;
};

export const getDiscountAmount = (product: LoadProductData): number =>
  +product?.price - +product?.discountedPrice;

export const getDaysOnSale = (
  product: LoadProductData,
  isGiftCard: boolean,
): number | string => {
  let madeAvailableAt =
    product?.madeAvailableAt || product?.made_available_at;

  if (typeof madeAvailableAt === 'number') {
    madeAvailableAt = new Date(
      madeAvailableAt * MILLISECONDS_MULTIPLIER,
    ).toDateString(); // This convert UNIX timestamps
  }

  // NB for 'saleEnd', product.soldAt can be inconsistent due to
  // returned products having  an outdated value
  if (
    isGiftCard ||
    product?.productType === PRODUCT_TYPES.GIFT_CARD ||
    product?.isSwagItem
  ) {
    // GC or swag item
    return 0;
  }

  if (madeAvailableAt) {
    const saleStart = moment(madeAvailableAt);
    const saleEnd = moment();

    return saleEnd.diff(saleStart, 'days');
  }
  return 0;
};
